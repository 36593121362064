import Jasmin from '../images/jasmin_mittag.jpg'
import Lauren from '../images/Lauren_Harkness.jpg'



const reviews = [
    {
    name: 'Jasmin Mittag',
    image: Jasmin,
    review: `"What a pleasure to work with Lurie! She developed a website for my artistic education project. On the one hand, I was very impressed with her programming skills. 
    On the other, she took the project and its external image to the next level with her creative ideas. 
    It was special to experience that level of co-creation with a developer. I really want to say: Thank you, Lurie, for your professional collaboration and support."`,
    project: `What's Her Name Again?`
    
    },
    {
    name: 'Lauren Harkness',
    image: Lauren,
    review: `"My website needs have evolved over the years and today it seems like there are layers upon 
    layers of old and new platforms and plugins, making it challenging to manage my site. Lurie came in 
    and dug deep into my Shopify setup to help untangle a few legacy issues. She accomplished our goals
    efficiently, and without causing any errors to existing functionality. It's a pleasure to work with a 
    woman who is both professional and friendly as well!"`,
    project: 'Shopify Website'
    
    }
    

]

export default reviews